<template>
  <Layout :footer-style="1">
    <div class="container">
      <Breadcrumb root-url="/software-service" parent-url="软件服务" current-url="网盘" style="border: none;"/>
    </div>

    <div class="rn-service-area rn-section-gapBottom pt--100 bg-color-light area-2">
      <div class="container">
        <div class="product">
          <img class="pic" src="@/assets/images/product/n8.png" />
          <div class="about">
            <div class="title">网盘</div>
            <div class="content">
              又称网络U盘、网络硬盘，是由互联网公司推出的在线存储服务。服务器机房为用户划分一定的磁盘空间，为用户免费或收费提供文件的存储、访问、备份、共享等文件管理等功能，并且拥有高级的世界各地的容灾备份。
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- Start Service Area -->
    <div class="rn-service-area rn-section-gapBottom pt--60 area-2">
      <div class="container">
        <div class="row">
          <div class="col-lg-12">
            <div class="section-title" data-aos="fade-up">
              <h3 class="title color-gray">爱数</h3>
            </div>
          </div>
          <div class="col-lg-12 text-center">
            <img class="company-logo" src="@/assets/images/brand/brand-33.png">
            <div class="company-info">
              爱数推出基于AI洞察力的大数据基础设施，以创新的产品与技术平台为客户提供整合、治理、洞察与保护的全域数据能力。通过AnyBackup灾备云、AnyShare文档云、AnyRobot日志云，提供数据服务，实现行业数字化的数据赋能。爱数的大数据基础设施，以云原生的技术架构、平台化的产品架构、人工智能的数据服务架构为核心技术，形成了包括AnyBackup副本数据管理解决方案、AnyShare内容分析解决方案、AnyRobot统一日志管理解决方案、AnyRobot防勒索病毒主动数据保护等创新的数字化方案。
            </div>
          </div>
        </div>

        <Separator style="margin: 80px 0;"/>

        <div class="row" data-aos="fade-up">
          <div class="col-lg-12">
            <div class="section-title">
              <h3 class="title color-gray">联想</h3>
            </div>
          </div>
          <div class="col-lg-12 text-center">
            <img class="company-logo" src="@/assets/images/brand/brand-20.png">
            <div class="company-info">
              联想集团是一家成立于中国、业务遍及180个市场的全球化科技公司。联想聚焦全球化发展，持续开发创新技术，致力于建设一个更加包容、值得信赖和可持续发展的数字化社会，引领和赋能智能化新时代的转型变革，为全球数以亿计的消费者打造更好的体验和机遇。联想作为全球领先ICT科技企业，秉承“智能，为每一个可能”的理念，联想持续研究、设计与制造全球最完备的端到端智能设备与智能基础架构产品组合，为用户与全行业提供整合了应用、服务和最佳体验的智能终端，以及强大的云基础设施与行业智能解决方案。
            </div>
          </div>
        </div>

      </div>
    </div>
    <!-- End Service Area -->
  </Layout>
</template>

<script>
import Layout from '@/components/common/Layout'
import Icon from '@/components/icon/Icon'
import Separator from '@/components/elements/separator/Separator'
import SectionTitle from '@/components/elements/sectionTitle/SectionTitle'
import Breadcrumb from '@/components/elements/breadcrumb/Breadcrumb'
export default {
  name: 'Product',
  components: {
    SectionTitle,
    Separator,
    Icon,
    Breadcrumb,
    Layout
  },
  data() {
    return {
    }
  }
}
</script>
<style lang="scss" scoped>
.display-block{
  display: block;
}
.area-2{
  .icon {
    .p-img {
      width: 64px;
      height: 64px;
    }
  }
  .more{
    display: inline-flex;
    color: #2261AA;
    img {
      width: 27px;
      height: 27px;
    }
    .i1{
      margin-left: -4px;
    }
    .i2{
      margin-left: -20px;
    }
  }
}
.product{
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  .pic{
    height: 220px;
  }
  .about{
    margin-left: 40px;
    color: #000000;
    .title{
      font-size: 32px;
      font-weight: bold;
    }
    .content{
      margin-top: 12px;
      font-size: 20px;
    }
  }
}
.company-logo{
  width: 320px;
}
.company-info{
  font-size: 24px;
  color: #000000;
  text-align: left;
  margin-top: 20px;
}
</style>
